import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import InputMask from 'react-input-mask'
// import {Container, Row, Col} from 'react-bootstrap'

// import RefButton from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefButton"
// import UploadButton from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefUpload"


const MainContactForm = () => {
  const WEBSITE_URL = 'https://chan.gatsbyjs.club';
  const FORM_ID = '36'; //Form id that provides Contact Form 7

  const [token, setToken] = useState() // store token
  // const [uploadMsg, setUploaded] = useState('')
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: 'post',
      url: `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`,
      data: {
        username: 'chanSubscriber', // provide a user credential with subscriber role
        password: ')kozAiR#)OJ7LT#n^G%frHSP'
      },
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(response => {
      setToken(response.data.token)
    }).catch(error => console.error( 'Error', error ))
  }, [])




  // UploadA File 
//   const activateFileInput = (file) => {
//     const inputFile = document.getElementById('form-file');
//     inputFile.click();
//     setInterval(function () {
//       if (document.getElementById('add-file-button')===null) {
//           return;
//       }
//         if (inputFile.files[0]) {
//             document.getElementById('add-file-button').classList.add('add');
//             setUploaded('');
//         }
//     }, 1000)
// }

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    isSubmitting,
    values,
    handleSubmit,
    // setFieldValue,
  } = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      // file: '',
      message: '',      
    },
    onSubmit: ({
      fullname,
      email,
      phone,
      // file,
      message
    }, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      // here we created a FormData field for each form field
      const bodyFormData = new FormData();
      bodyFormData.set('fullname', fullname);
      bodyFormData.set('email', email);
      bodyFormData.set('phone', phone);
      // bodyFormData.append('file-151', file);
      bodyFormData.set('message', message);
      
      // here we sent
      axios({
        method: 'post',
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      }).then(response => {
        // actions taken when submission goes OK
        resetForm()
        setSubmitting(false)
        setMessageSent(true)
        setIsSuccessMessage(true)
      }).catch(error => {
        // actions taken when submission goes wrong
        setSubmitting(false)
        setMessageSent(false)
        setIsSuccessMessage(false)
      })
    },
  })



  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000);
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
     <>
        <form onSubmit={handleSubmit} className="offer__form form feedback__form">
            <div class="form__row">
                <div class="form__group">
                    {/* <label htmlFor="fullname">Ваше имя*</label> */}
                    <input
                        className="input-field feedback__form-field"
                        id="fullname"
                        name="fullname"
                        type="text"
                        placeholder= "Ваше имя*"
                        onChange={handleChange}
                        value={values.fullname}
                        required
                    />
                    {/* <label htmlFor="email">E-mail*</label> */}
                    <input
                        className="input-field feedback__form-field"
                        id="email"
                        name="email"
                        type="email"
                        placeholder= "E-mail*"
                        onChange={handleChange}
                        value={values.email}
                        required
                    />
                    {/* <label htmlFor="phone">Телефон*</label> */}
                    <InputMask
                        className="input-field feedback__form-field"
                        mask="+7-999-999-99-99"
                        id="phone"
                        name="phone"
                        type="phone"
                        placeholder= "Телефон*"
                        onChange={handleChange}
                        value={values.phone}
                        required
                    />
                </div>
                <div class="form__group">
                    {/* <label htmlFor="message">Сообщение*</label> */}
                    <textarea
                        className="input-field input-textarea"
                        id="message"
                        name="message"
                        type="text"
                        placeholder= "Сообщение*"
                        onChange={handleChange}
                        value={values.message}
                        required
                    />
                </div>
                </div>
                    <button
                        className="btn-chan form__send-btn feedback__send-btn"  
                        type="submit"
                        value="Send Message"
                        disabled={isSubmitting}
                    >
                        Отправить
                    </button>
                <div className="footer-form">
                    <p style={{color: '#fff'}}>Отправляя данную форму, вы даете согласие на обработку персональных данных</p>
                </div>
                    
                {messageSent && (
                <div className="form-sending-message">Ваше сообщение отправляется!</div>
                )}
        </form>
    </>
    
  )
}

export default MainContactForm