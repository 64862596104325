import React from 'react';


export default function SliderNextButton() {
    return (
      <svg viewBox="0 0 200 200" id="expbutton">
        <g>
            <circle 
                class="circle"
                cx="100" 
                cy="100" 
                r="100" 
                fill="#ffffff" 
            />
            <polyline 
                class="polyline arrow-right"
                points="70,50, 130,100, 70,150" 
            />
        </g>
      </svg>
     
    );
  }