import React, {useState} from "react"
import { graphql } from "gatsby"
import {Container, Row, Col, Tabs, Tab, Modal} from "react-bootstrap"
import FsLightbox from 'fslightbox-react';
import Slider from "react-slick";
import NextButton from "../components/Elements/Buttons/SliderButtons/NextButton"
import PrevButton from "../components/Elements/Buttons/SliderButtons/PrevButton"

import Seo from "../components/seo";

import Layout from "../components/layout"
import ContactForm from "../components/Modules/MainForm"


// Gallery functions to move slides with Buttons

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button 
      className = {className}
      style={{ ...style, display: "block", background: "transparent", right: "130px"}}
      onClick={onClick}
    >
    <NextButton />
    </button>

  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button 
      className = {className}
      style={{ ...style, display: "block", background: "transparent", left: "5px", zIndex: 2}}
      onClick={onClick}
    >
    <PrevButton />
    </button>

  );
}

// Gallery functions to move slides with Buttons



export default function Project({ data }) {

  function ControlledTabs() {
    const [key, setKey] = useState('home');
  
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="Описание">
          {postDescription}
        </Tab>
        <Tab eventKey="additional" title="Дополнительно">
          {postAdditional}
        </Tab>
        {/* <Tab eventKey="contact" title="Contact">
          Some text 3
        </Tab> */}
      </Tabs>
    );
  }

  function OrderModal() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={handleShow}>ЗАКАЗАТЬ</button>
  
        <Modal show={show} onHide={handleClose}       size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header closeButton>
            <Modal.Title>Отправьте заказ на товар заполнив небольшую форму ниже:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Link to="/contacts">Отправьте запрос на странице Контакты</Link> */}
            <div style={{backgroundColor: '#77a237', display: 'grid', justifyContent: 'center'}}>
              <ContactForm />
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <p>Мы свяжемся с Вами, как можно скорее!</p>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0
  });

  const slidersettings = {
    // customPaging: function(i) {
    //   return (
    //     <a>{ImgBtn}</a>
    //   );
    // },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider",
    variableWidth: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          variableWidth: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          centerMode: true,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          // centerMode: true,
        }
      }
    ]
};

      // CONTENT 
    
    const images= data.allWpPost.nodes[0]
    const ProductGallery = images.productGallery.productImages.map(({localFile}, index) => (
      <button className="imagebutton" onClick={() => openLightboxOnSlide(index + 1)} >
        <img src = {localFile.childImageSharp.original.src} style={{width: '25vw'}} alt="" />
      </button>
        ))
    const Sources = images.productGallery.productImages.map(({localFile, id}) => (
          <img src = {localFile.childImageSharp.original.src} key={id} alt="" />
        ))

        function openLightboxOnSlide(number) {
          setLightboxController({
          toggler: !lightboxController.toggler,
          slide: number
        });
      }
        // END GALLERY VARS

    const postTitle = data.allWpPost.nodes.map(({title}) => (<h2 dangerouslySetInnerHTML={{ __html: title }} />))
    const postContent = data.allWpPost.nodes.map(({content}) => (<div dangerouslySetInnerHTML={{ __html: content }} />))
    const postDescription = data.allWpPost.nodes.map(({productDescription}) => (<div dangerouslySetInnerHTML={{ __html: productDescription.productDescription }} />))
    const postAdditional = data.allWpPost.nodes.map(({productDescription}) => (<div dangerouslySetInnerHTML={{ __html: productDescription.productAdditional }} />))
    const productPrice = data.allWpPost.nodes.map(({productTitles}) => (<div className="price" dangerouslySetInnerHTML={{ __html: productTitles.productPrice }} />))



      //SEO THINGS
    const SeoTitle = data.allWpPost.nodes.map(({seoqueries}) => (seoqueries.title))
    const SeoDescription = data.allWpPost.nodes.map(({seoqueries}) => (seoqueries.description)) 
    const SeoKeywords = data.allWpPost.nodes.map(({seoqueries}) => (seoqueries.keywords))
      //END SEO THINGS
    

    return(
        <>
            <Seo 
              title = {SeoTitle}
              description = {SeoDescription}
              keywords = {SeoKeywords.toString()}
            />

           

            <Layout>
              <Container>
                <Row>
                  <Col xl={5}>
                  <FsLightbox
                    toggler={lightboxController.toggler}
                    slide={lightboxController.slide}
                    sources={Sources}
                  />
                    {/* {postImage} */}
                    <Slider {...slidersettings}>

                      {ProductGallery}

                    </Slider>
     
                  </Col>
                  <Col>
                    {postTitle}
                    {postContent}
                    <Row>
                    <Col xl={6}>{productPrice}</Col>
                    <Col xl={6}><OrderModal /></Col>
                    </Row>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <ControlledTabs />
                  </Col>
                </Row>
              </Container>
                
            </Layout>
        
        </>
    )
}




export const query = graphql`
  query(
    $slug: String!
    ) {
    allWpPost(filter: { slug: { eq: $slug }}) {
        nodes {
          slug
          content
          excerpt
          title
          seoqueries {
            title
            description
            keywords
          }
          productTitles {
            productName
            productSubname
            productPrice
          }
          productDescription {
            productDescription
            productAdditional
          }
          productGallery {
            productImages {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        
      }
    }
    `