import * as React from "react"
import PropTypes from "prop-types"

import Header from "../components/MainPage/header"
import Footer from "../components/MainPage/footer"

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../styles/global';
import { theme } from '../styles/theme';





const Layout = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
        <div  className="chans">
            <div className="container">
                <Header />
            </div>
              <main>{children}</main>
            <div className="container">
              <Footer />
            </div>
        </div>
    </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout